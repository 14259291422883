import { CanActivateFn } from '@angular/router';
import { AddressAlertService } from '../services/address-alert.service';
import { inject } from '@angular/core';
import { MessageService } from '@corelabs/angular-messages';
import { TranslateService } from '@ngx-translate/core';
import { ShippingAddressesFacade } from '@app/store/shipping-addresses';
import { filter, mergeMap, take, tap } from 'rxjs';
import { UserFacade } from '@app/store/user';

export function addressAlertGuard(): CanActivateFn {
    return () => {
        const userFacade = inject(UserFacade);
        const addressAlertService = inject(AddressAlertService);
        const messageService = inject(MessageService);
        const translateService = inject(TranslateService);
        const shippingAddressesFacade = inject(ShippingAddressesFacade);

        userFacade.features$
            .pipe(
                take(1),
                filter((features) => features.includes('show-shipping-addresses')),
                mergeMap(() =>
                    shippingAddressesFacade.addresses$.pipe(
                        take(1),
                        tap((addresses) => {
                            if (!addressAlertService.get() && addresses.length > 1) {
                                messageService.clear();
                                messageService.add(translateService.instant('select-address-info'), 'info');
                                addressAlertService.set();
                            }
                        }),
                    ),
                ),
            )
            .subscribe();

        return true;
    };
}
