import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { constants } from '@const';
import { ShippingAddressesFacade } from '@app/store/shipping-addresses';
import { UserFacade } from '@app/store/user';

@Injectable({
    providedIn: 'root',
})
export class ShippingAddressSwitchInterceptor implements HttpInterceptor {
    private shippingAddressesFacade = inject(ShippingAddressesFacade);
    private userFacade = inject(UserFacade);

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const key = constants.addressSwitch.keys.header;
        const currentAddress = this.shippingAddressesFacade.currentAddress;
        const user = this.userFacade.user;
        const locationCode = currentAddress?.locationCode || user?.impersonate?.locationCode || user?.salesperson?.locationCode || '';

        if (locationCode) {
            const modifiedReq = req.clone({
                headers: req.headers.set(key, locationCode),
            });
            return next.handle(modifiedReq);
        }

        return next.handle(req);
    }
}
